/* eslint-disable consistent-default-export-name/default-export-match-filename */
'use client'

import classNames from 'classnames'
import styles from './FormSubmitButton.module.css'
import { IconButton, type IconButtonProps } from '@betterplace/design-system/client'
import type { FormSubmitButtonProps } from './types'
// eslint-disable-next-line import/exports-last
function FormSubmitButton({
  iconProps,
  iconName,
  className,
  busy,
  fullWidth = 'mobile-only',
  ...props
}: FormSubmitButtonProps) {
  return (
    <IconButton<'button'>
      {...(props as IconButtonProps<'button'>)}
      className={classNames({ [styles.loading!]: busy }, className)}
      as="button"
      type="submit"
      busy={busy}
      iconName={busy ? 'spinner' : iconName}
      iconProps={{ color: 'bg-light', size: '400', ...iconProps }}
      fullWidth={fullWidth}
    />
  )
}

export default FormSubmitButton
