/* eslint-disable import/exports-last */
import fetchAPIv4 from '@/helpers/fetchAPIv4'
import useInterval from '../useInterval'
import { cache } from './cache'
import { useCallback, useEffect, useRef, useState } from 'react'
import type { InferResponseTypeFromUrl } from '@betterplace/api-v4-types'
import type { Locale } from '@/i18n/types'

export type UseAPIv4Result<T> =
  | {
      pending: true
      error?: undefined
      data?: T
    }
  | {
      pending: false
      error: Error
      data?: T
    }
  | {
      pending: false
      error?: undefined
      data: T
    }

export type UseAPIv4Options<
  TUrl extends string,
  TResponse extends InferResponseTypeFromUrl<'get', TUrl>,
  TSelection,
  TSelector extends (response: TResponse) => TSelection,
> = {
  path: TUrl
  locale: Locale
  intervalMs?: number
  selector: TSelector
  demoData?: TSelection | undefined
}

function useAPIv4<
  TUrl extends string,
  TResponse extends InferResponseTypeFromUrl<'get', TUrl>,
  TSelection,
  TSelector extends (response: TResponse) => TSelection,
>({
  path,
  locale,
  intervalMs = 1000,
  demoData,
  selector,
}: UseAPIv4Options<TUrl, TResponse, TSelection, TSelector>): UseAPIv4Result<TSelection> {
  const [data, setData] = useState<TSelection | undefined>(undefined)
  const dataHash = useRef<string | null>(null)
  const demoDataExists = !!demoData
  useEffect(() => {
    setData(undefined)
    dataHash.current = null
  }, [demoDataExists])

  const setDataWithComparison = useCallback(
    (next: TResponse) => {
      const selected = typeof selector === 'function' ? selector?.(next) : (next as unknown as TSelection)
      const nextHash = JSON.stringify(selected)
      if (dataHash.current !== nextHash) {
        setData(selected)
        dataHash.current = nextHash
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const [pending, setPending] = useState(true)
  const setPendingToFalse = useCallback(() => setPending(false), [setPending])
  const [error, setError] = useState<Error | undefined>(undefined)
  const callback = useCallback(
    async (signal?: AbortSignal) => {
      if (demoData) {
        setData(demoData)
        dataHash.current = JSON.stringify(demoData)
        setPendingToFalse()
        return
      }
      setPending(true)
      const cached = cache.data[path] as TResponse | undefined
      if (cached) {
        setDataWithComparison(cached)
      }
      try {
        const response = await fetchAPIv4(path, { locale, signal })
        if (!response.ok) throw new Error(response.statusText)
        const payload = await response.json()
        setDataWithComparison(payload as TResponse)
        cache.data[path] = payload
      } catch (e) {
        setError(e as Error)
      } finally {
        setPendingToFalse()
      }
    },
    [demoData, locale, path, setPendingToFalse, setDataWithComparison]
  )
  useInterval({ callback, executeImmediately: true, intervalMs, passAbortSignal: !!demoData })
  return { data, pending, error } as UseAPIv4Result<TSelection>
}

export default useAPIv4
