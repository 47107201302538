/* eslint-disable import/prefer-default-export */
import getHexColorFromNamed from './getHexColorFromNamed'
function clamp(min: number, value: number, max: number) {
  return Math.max(min, Math.min(value, max))
}
export function brightenColor(color: string, percent: number): string {
  if (color === 'transparent') return color
  const color_ = getHexColorFromNamed(color) ?? color
  const num = parseInt(color_.replace('#', ''), 16)
  const amt = Math.round(2.55 * percent)
  const R = clamp(0, ((num >> 16) & 255) + amt, 255)
  const G = clamp(0, ((num >> 8) & 255) + amt, 255)
  const B = clamp(0, (num & 255) + amt, 255)
  const components = [R, G, B] as const

  const stringified = components.map((component) => {
    const normalized = component >= 0 ? component : 0
    return normalized.toString(16).padStart(2, '0')
  })
  // construct the color back from the RGB components
  return `#${stringified.join('')}`
}

export function darkenColor(color: string, percent: number): string {
  return brightenColor(color, -1 * percent)
}

export function addAlphaToColor(color: string, percent: number): string {
  if (color === 'transparent') return color
  const color_ = getHexColorFromNamed(color) || color
  const amt = Math.round(2.55 * percent)
  return `${color_}${amt.toString(16).padStart(2, '0')}`
}

export function getColorLuminance(color: string): number {
  if (color === 'transparent') return 0
  const color_ = getHexColorFromNamed(color) ?? color

  // Convert hex to RGB values between 0-1
  const r = parseInt(color_.slice(1, 3), 16) / 255
  const g = parseInt(color_.slice(3, 5), 16) / 255
  const b = parseInt(color_.slice(5, 7), 16) / 255

  // Convert to sRGB
  const rsRGB = r <= 0.03928 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4)
  const gsRGB = g <= 0.03928 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4)
  const bsRGB = b <= 0.03928 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4)

  // Calculate luminance
  return 0.2126 * rsRGB + 0.7152 * gsRGB + 0.0722 * bsRGB
}
